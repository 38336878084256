var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "page" },
    [
      _c("h1", { staticClass: "page__heading" }, [_vm._v("Source Suppliers")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "quotation-hiw__button old-form__button",
          attrs: { to: { name: "quotationForm" } },
        },
        [_vm._v("Proceed")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__content post-content" }, [
      _c(
        "p",
        {
          staticClass:
            "post-content__highlighted post-content__highlighted--small",
        },
        [
          _vm._v(
            "\n      Welcome, you are about to send a quotation request to the registered suppliers at FindSourcing."
          ),
          _c("br"),
          _vm._v(
            "\n      Relevant suppliers will receive the information, matching your company profile and product expectation. They\n      respond to the enquiry and you´ll be contacted.\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass:
            "post-content__subheading post-content__subheading--center",
        },
        [_vm._v("How it works")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "quotation-hiw" }, [
      _c("li", { staticClass: "quotation-hiw__item" }, [
        _c("strong", { staticClass: "quotation-hiw__title" }, [
          _vm._v("Send Quotation Request"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "quotation-hiw__icon",
          attrs: { src: require("../img/quotation/s01.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "quotation-hiw__text" }, [
          _vm._v("You are sending out a quotation request."),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "quotation-hiw__item" }, [
        _c("strong", { staticClass: "quotation-hiw__title" }, [
          _vm._v("Global Sourcing"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "quotation-hiw__icon",
          attrs: { src: require("../img/quotation/s02.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "quotation-hiw__text" }, [
          _vm._v("FindSourcing conducts sourcing according to request."),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "quotation-hiw__item" }, [
        _c("strong", { staticClass: "quotation-hiw__title" }, [
          _vm._v("Quotation Preparation"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "quotation-hiw__icon",
          attrs: { src: require("../img/quotation/s03.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "quotation-hiw__text" }, [
          _vm._v("Contacted suppliers prepare options."),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "quotation-hiw__item" }, [
        _c("strong", { staticClass: "quotation-hiw__title" }, [
          _vm._v("Deal Evaluation"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "quotation-hiw__icon",
          attrs: { src: require("../img/quotation/s04.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "quotation-hiw__text" }, [
          _vm._v("FindSourcing filters options."),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "quotation-hiw__item" }, [
        _c("strong", { staticClass: "quotation-hiw__title" }, [
          _vm._v("Deal Selection"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "quotation-hiw__icon",
          attrs: { src: require("../img/quotation/s05.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "quotation-hiw__text" }, [
          _vm._v("You are evaluating deals to decide next step."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }