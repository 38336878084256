<template>
  <article class="page">
    <h1 class="page__heading">Source Suppliers</h1>
    <div class="page__content post-content">
      <p class="post-content__highlighted post-content__highlighted--small">
        Welcome, you are about to send a quotation request to the registered suppliers at FindSourcing.<br />
        Relevant suppliers will receive the information, matching your company profile and product expectation. They
        respond to the enquiry and you´ll be contacted.
      </p>
      <h2 class="post-content__subheading post-content__subheading--center">How it works</h2>
    </div>
    <ul class="quotation-hiw">
      <li class="quotation-hiw__item">
        <strong class="quotation-hiw__title">Send Quotation Request</strong>
        <img src="../img/quotation/s01.png" alt="" class="quotation-hiw__icon" />
        <p class="quotation-hiw__text">You are sending out a quotation request.</p>
      </li>
      <li class="quotation-hiw__item">
        <strong class="quotation-hiw__title">Global Sourcing</strong>
        <img src="../img/quotation/s02.png" alt="" class="quotation-hiw__icon" />
        <p class="quotation-hiw__text">FindSourcing conducts sourcing according to request.</p>
      </li>
      <li class="quotation-hiw__item">
        <strong class="quotation-hiw__title">Quotation Preparation</strong>
        <img src="../img/quotation/s03.png" alt="" class="quotation-hiw__icon" />
        <p class="quotation-hiw__text">Contacted suppliers prepare options.</p>
      </li>
      <li class="quotation-hiw__item">
        <strong class="quotation-hiw__title">Deal Evaluation</strong>
        <img src="../img/quotation/s04.png" alt="" class="quotation-hiw__icon" />
        <p class="quotation-hiw__text">FindSourcing filters options.</p>
      </li>
      <li class="quotation-hiw__item">
        <strong class="quotation-hiw__title">Deal Selection</strong>
        <img src="../img/quotation/s05.png" alt="" class="quotation-hiw__icon" />
        <p class="quotation-hiw__text">You are evaluating deals to decide next step.</p>
      </li>
    </ul>
    <router-link :to="{ name: 'quotationForm' }" class="quotation-hiw__button old-form__button">Proceed</router-link>
  </article>
</template>

<script>
export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Source Suppliers`;
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.quotation-hiw {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: math.div(130rem, 1.6);
  margin: math.div(2rem, 1.6) auto;
  padding: 0 math.div(1.5rem, 1.6);
  list-style: none;
  @include tablet-landscape-min {
    flex-wrap: nowrap;
    margin: math.div(5rem, 1.6) auto;
  }
  &__item {
    position: relative;
    width: 100%;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: math.div(5rem, 1.6);
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: math.div(2.8rem, 1.6);
        height: math.div(0.8rem, 1.6);
        top: 100%;
        left: 50%;
        margin-left: math.div(-1.4rem, 1.6);
        margin-top: math.div(1.5rem, 1.6);
        background: url('../img/quotation/arrow.png') no-repeat 50% 50%;
        background-size: cover;
        transform: rotate(90deg);
      }
    }
    @include tablet-landscape-min {
      width: 15%;
      &:not(:last-child) {
        &:after {
          width: math.div(2.8rem, 1.6);
          height: math.div(0.8rem, 1.6);
          top: 50%;
          left: auto;
          right: math.div(-4rem, 1.6);
          margin-top: math.div(-2rem, 1.6);
          margin-left: 0;
          transform: none;
        }
      }
    }
    @include desktop-min {
      &:not(:last-child) {
        &:after {
          width: math.div(5.6rem, 1.6);
          height: math.div(1.6rem, 1.6);
          right: math.div(-6rem, 1.6);
        }
      }
    }
  }
  &__title {
    display: block;
    @include tablet-landscape-min {
      line-height: math.div(2.4rem, 1.6);
      min-height: math.div(4.8rem, 1.6);
    }
    @include desktop-min {
      min-height: 0;
    }
  }
  &__icon {
    display: block;
    height: math.div(8rem, 1.6);
    width: auto;
    margin: math.div(1rem, 1.6) auto;
    @include tablet-landscape-min {
      height: math.div(7rem, 1.6);
    }
    @include desktop-min {
      height: math.div(10rem, 1.6);
    }
  }
  &__text {
    font-size: math.div(1.4rem, 1.6);
  }
  &__button {
    margin: 0 auto math.div(2rem, 1.6);
    max-width: math.div(30rem, 1.6);
    @include tablet-landscape-min {
      margin-bottom: math.div(5rem, 1.6);
    }
  }
}
</style>
